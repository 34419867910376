import React, {useEffect, useState} from 'react';
import {
    calculateDiscountPercentage, checkRequiredFields,
    checkTime,
    formatCurrency,
    formatDate,
    getArrayValues,
    getDaySlots, notify, user
} from "../../helpers";
import removeCart from "../../redux/actions/removeCart";
import {Link} from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import getAddresses from "../../redux/actions/getAddresses";
import AddressModal from "../../components/modals/address-modal";
import FreeDelivery from "../../components/addCart/free-delivery";
import getTimeSlots from "../../redux/actions/getTimeSlots";
import {Button, ButtonGroup, FloatingLabel, Form, ListGroup} from "react-bootstrap";
import InputMask from "react-input-mask";
import getPlaceOrder from "../../redux/actions/getPlaceOrder";
import Addresses from "../../components/other/addresses";
import * as PropTypes from "prop-types";
import getPromoCodes from "../../redux/actions/getPromoCodes";
import {Swiper, SwiperSlide} from "swiper/react";

function Icon(props) {
    return null;
}

Icon.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string
};
const Checkout = ({define, addItem}) => {
    const [orderAddress, setOrderAddress] = useState(false);
    const [address, setAddress] = useState(false);
    const [cart, setCart] = useState([]);
    const [checkout, setCheckout] = useState([]);
    const [deliveryType, setDeliveryType] = useState('0');
    const [timeSlots, setTimeSlots] = useState([]);
    const [dateSlots, setDateSlots] = useState([]);
    const [selectedDate, setSelectedDate] = useState(0);
    const [selectedTime, setSelectedTime] = useState(false);
    const [currentTime, setCurrentTime] = useState(false);
    const [paymentType, setPaymentType] = useState('0');
    const [codType, setCodType] = useState(0);
    const [orderTotal, setOrderTotal] = useState(0);
    const [completeOrder, setCompleteOrder] = useState(false);
    const [dontRing, setDontRing] = useState(false);
    const [contactless, setContactless] = useState(false);
    const [promoCodes, setPromoCodes] = useState([]);
    const [activePromoCode, setActivePromoCode] = useState([]);
    const [cardValue, setCardValue] = useState({
        card_number: '',
        date: '',
        cvv: ''
    });

    useEffect(() => {
        const updateCurrentTime = () => {
            const now = new Date();
            now.setHours(16, 0, 0); // Saati 16:00:00 olarak ayarla
            const formattedTime = now.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', second: '2-digit'});
            setCurrentTime(formattedTime);
        };
        const intervalId = setInterval(updateCurrentTime, 1000);
        return () => clearInterval(intervalId);
    }, []);
    useEffect(() => {
        setCart(define.temp.cart);
        setCheckout(define.temp.checkout);
        if (define.temp.checkout) {
            setOrderTotal(define.temp.checkout?.total_amount)
        }

        addItem('temp.refresh_cart', false);
    }, [define.temp.cart, define.temp.checkout]);
    useEffect(() => {
        if(define.temp.checkout.sub_total){
            getPromoCodes(define.temp.checkout?.sub_total).then(r => {
                if(r.data){
                    setPromoCodes(r.data);
                }
            })
        }
    }, [define.temp.checkout.sub_total]);
    useEffect(() => {
        addItem('temp.refresh_cart', true);
    }, [deliveryType]);

    useEffect(() => {
        getTimeSlots().then(r => {
            setTimeSlots(r.data.time_slots)
            const allowed_days = r.data?.time_slots_allowed_days;
            setDateSlots(getDaySlots(allowed_days));
        })
    }, []);
    const handleDeliveryType = (type) => {
        if (type && type !== deliveryType) {
            console.log(type, deliveryType);
            addItem('temp.loading', true);
            setDeliveryType(!type ? '0' : type)
            addItem('temp.delivery_type', !type ? '0' : type);
        } else {
            return false;
        }
    }

    const handlePaymentType = (type) => {
        if (type && type !== paymentType) {
            setPaymentType(!type ? '0' : type)
        } else {
            return false;
        }
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;

        // Sadece sayıları kabul et
        if (/^\d*$/.test(value)) {
            setCardValue((prevInput) => ({
                ...prevInput,
                [name]: value
            }));
        }
    };
    const handlePromoCode = (promoCode) => {
        addItem('temp.loading', true);
        setTimeout(() => {
            setActivePromoCode(promoCode)
            addItem('temp.loading', false);
        }, 1000)
    }
    const handleSubmit = () => {
        addItem('temp.loading', true);
        let order_note = {
            note: '',
            dont_ring: dontRing,
            contactless: contactless
        }
        let data = {
            product_variant_id: getArrayValues(define.temp.cart.cart, 'product_variant_id').join(','),
            quantity: getArrayValues(define.temp.cart.cart, 'qty').join(','),
            total: define.temp.cart.sub_total,
            delivery_charge: parseInt(define.temp.checkout?.delivery_charge?.total_delivery_charge),
            final_total: define.temp.checkout?.total_amount,
            payment_method: paymentType === '1' ? 'COD' : 'CARD',
            cod_method: paymentType === '1' ? codType : null,
            address_id: orderAddress ? orderAddress.id : false,
            delivery_type: deliveryType,
            order_note: order_note,
            promocode_id: activePromoCode && activePromoCode.id ? activePromoCode.id : false,
            delivery_time: selectedDate && selectedTime ? selectedDate + ' ' + selectedTime : false
        }
        console.log(data)
        let requiredFields = {
            'total': 'Alışveriş tutarı 0\'dan büyük olmalıdır.',
            'delivery_time': 'Siparişinizin, hangi zaman aralığında olacağını seçmelisiniz.',
            'final_total': 'Ödeme tutatının 0\'dan büyük olması gerekmektedir.',
            'payment_method': 'Ödeme metodunuzu seçin ve yeniden deneyin.',
            'address_id': 'Siparişiniz için bir adres seçmeniz gerekmektedir.',
            'quantity': 'Siparişiniz içerisinde en az 1 ürün olmalıdır.'
        }
        let checkFields = checkRequiredFields(data, Object.keys(requiredFields));
        if (checkFields) {
            addItem('temp.loading', false)
            notify(requiredFields[checkFields])
        } else {
            getPlaceOrder(data).then(r => {
                if (r.status) {
                    addItem('temp.loading', false)
                    addItem('temp.refresh_cart', true);
                    setCompleteOrder(r.data.order_id)
                }
            });
        }
    }
    return (
        <>
            {!cart || completeOrder ? (
                <section>
                    <div className="container">
                        <div className="card">
                            {completeOrder ? (
                                <div className="card-body bg-success-subtle order-complete-card">
                                    <div className="py-4 text-center">
                                        <i className="fa-duotone fa-box-check text-success"></i>
                                        <h5 className="mt-4 mb-4">Siparişiniz alındı!</h5>
                                        <p>Siparişiniz ile ilgili detaylar, hesabınıza
                                            tanımlı <b>{user().email}</b> e-posta adresinize iletildi.</p>
                                        <Link to={'/siparisler'} className="btn btn-outline-success"><i
                                            className="fa-duotone fa-bags-shopping me-1"></i>Siparişler Listesine Gidin</Link>
                                        <div className="small-hr"></div>
                                        <h4 className="text-success">Sipariş Numarası</h4>
                                        <p className="h2 text-dark mb-4">{completeOrder}</p>
                                        <h4 className="text-success">Toplam Tutar</h4>
                                        <p className="h2 text-dark mb-4">{formatCurrency(orderTotal ?? 0)} TL</p>
                                        <h4 className="text-success">Teslimat Adresi</h4>
                                        <div className="text-dark mb-4">
                                            <p>{orderAddress.address}</p>
                                            <p className="mb-0">{orderAddress.state} / {orderAddress.area}</p>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="card-body">
                                    <div className="text-center td-bg-white py-4">
                                        <img src="./assets/images/no-cart.png" height={200} alt=""/>
                                        <h5 className="mt-4 mb-4">Sepetinizde hiç ürün yok!</h5>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </section>
            ) : (
                <>
                    <section className="breadscrumb-section p-0">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="breadscrumb-contain">
                                        <h2>Alışverişi Tamamla</h2>
                                        <nav>
                                            <ol className="breadcrumb mb-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={'/'}>
                                                        <i className="fa-solid fa-house"></i>
                                                    </Link>
                                                </li>
                                                <li className="breadcrumb-item active" aria-current="page">Alışverişi
                                                    Tamamla
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="cart-section section-b-space">
                        <div className="container">
                            <div className="row g-sm-5 g-3">
                                <div className="col-xxl-9">
                                    {promoCodes.length > 0 && (
                                        <div className="card mb-4">
                                            <div className="card-header">
                                                <h5 className="mb-3">Kampanyalar</h5>
                                            </div>
                                            <div className="card-body">
                                                <Swiper
                                                    spaceBetween={50}
                                                    slidesPerView={2}
                                                    loop={true}
                                                    onSlideChange={() => console.log('slide change')}
                                                    onSwiper={(swiper) => console.log(swiper)}
                                                >
                                                    {promoCodes.map((item, index) => (
                                                        <SwiperSlide className={"wow fadeInUp"}>
                                                                    <div className={`category-box-list ${activePromoCode.id === item.id ? 'active-campaign' : ''}`}>
                                                                        <div className="category-box-view">
                                                                                <img
                                                                                    src={item.image_url}
                                                                                    className="img-fluid rounded float-start me-3"
                                                                                    alt="" width={70} />
                                                                            <h6 className="lh-20">{item.promo_code_message}</h6>
                                                                            <p className={"m-0"}><b>İndirim Tutarı : {formatCurrency(item.discount)} TL</b></p>
                                                                            <div className="hover-area">
                                                                                <button
                                                                                    onClick={() => activePromoCode.id === item.id ? handlePromoCode([]) : handlePromoCode(item)}
                                                                                    className="btn shop-button d-block">
                                                                                    <span>{activePromoCode.id === item.id ? 'Kampanyayı Kaldır' : 'Kullan'}</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper>
                                            </div>
                                        </div>
                                        )}

                                    <div className="card">
                                        <div className="card-header"><h5 className="mb-3">Teslimat Seçenekleri</h5>
                                        </div>
                                        <div className="card-header">
                                            <Accordion activeKey={deliveryType} defaultActiveKey={deliveryType}
                                                       className="mb-4"
                                                       defaultChecked={deliveryType}
                                                       onSelect={(e) => handleDeliveryType(e)}>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header><span className="fw-bold"><i
                                                        className="fa-duotone fa-map-location me-2"></i> Adresime Teslim Edilsin</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <Addresses addItem={addItem} define={define}
                                                                   setDefault={setOrderAddress}
                                                                   defaultAddress={orderAddress}/>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header><span className="fw-bold"><i
                                                        className="fa-duotone fa-shop me-2"></i> Mağazadan Teslim Alacağım</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <img src="./assets/images/logo.png" alt=""/>
                                                        <a className="btn btn-outline-secondary float-end"><i
                                                            className="fa-duotone fa-map-location-dot"></i> Yol
                                                            Tarifi</a>
                                                        <hr/>
                                                        <p><b>Adres : </b>{define.temp.settings.store_address}</p>
                                                        <p><b>Telefon Numarası
                                                            : </b>{define.temp.settings.support_number}</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                            <ButtonGroup aria-label="Basic example" className="w-100 mb-4">
                                                {dateSlots && dateSlots.map((item, i) => (
                                                    <Button onClick={() => setSelectedDate(item)} size="sm"
                                                            variant={item === selectedDate ? 'secondary' : 'outline-secondary'}>{formatDate(item, 'd mmmm yyyy')}</Button>
                                                ))}
                                            </ButtonGroup>
                                        </div>
                                        {selectedDate ? (
                                            <div className="card-body">
                                                <ButtonGroup aria-label="Basic example">
                                                    {timeSlots && timeSlots.map((item, i) => (
                                                        <>
                                                            {checkTime(selectedDate, item.from_time) && (
                                                                <Button onClick={() => setSelectedTime(item.title)}
                                                                        variant={selectedTime === item.title ? 'success' : 'outline-secondary'}>
                                                                    <i className={`fa-duotone fa-square${selectedTime === item.title ? '-check' : ''} fs-3 mb-2 mt-2`}></i><br/>
                                                                    <b>{item.title}</b>
                                                                </Button>
                                                            )}
                                                        </>
                                                    ))}
                                                </ButtonGroup>
                                            </div>
                                        ) : (
                                            <div className="alert alert-warning alert-mini mt-3 ms-3 me-3">Teslimat
                                                seçeneklerini görmek için yukarıdaki tarihlerden, size en uygun tarihi
                                                seçin</div>
                                        )}

                                    </div>
                                    <div className="card mt-4">
                                        <div className="card-header"><h5 className="mb-3">Ödeme Seçenekleri</h5></div>
                                        <div className="card-body">
                                            <Accordion activeKey={paymentType} defaultActiveKey={paymentType}
                                                       defaultChecked={paymentType}
                                                       onSelect={(e) => handlePaymentType(e)}>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header><b><i
                                                        className="fa-duotone fa-credit-card me-2"></i> Kartla
                                                        ödeyin</b></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="form-group mb-3">
                                                                    <label htmlFor=""
                                                                           className="fw-bold mb-2 text-muted">Kart
                                                                        Numarası</label>
                                                                    <div className="position-relative">
                                                                        <InputMask mask="9999 9999 9999 9999"
                                                                                   className="form-control fw-bold"
                                                                                   placeholder="____ ____ ____ ____"
                                                                                   name="card_number"
                                                                                   onChange={handleInputChange}
                                                                        />
                                                                        <i className="fa-duotone fa-credit-card-front credit-card-icon"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-8 col-md-8">
                                                                        <div className="form-group">
                                                                            <label htmlFor=""
                                                                                   className="fw-bold mb-2 text-muted">Ay/Yıl</label>
                                                                            <InputMask mask="99/99"
                                                                                       className="form-control fw-bold"
                                                                                       placeholder="__/__"
                                                                                       name="date"
                                                                                       onChange={handleInputChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-4">
                                                                        <div className="form-group">
                                                                            <label htmlFor=""
                                                                                   className="fw-bold mb-2 text-muted">CVV</label>
                                                                            <InputMask mask="999"
                                                                                       className="form-control fw-bold"
                                                                                       placeholder="___"
                                                                                       name="cvv"
                                                                                       onChange={handleInputChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                <div
                                                                    className="card bg-gray border-0 mt-4 p-4 text-dark">
                                                                    <b className="text-muted mb-2 fs-6">Hızlı ve güvenli
                                                                        alışveriş
                                                                        için kartını kaydet!</b>
                                                                    <div className="">
                                                                        <i className="fa-duotone fa-square fs-3 mb-2 mt-2 float-start me-2"></i>
                                                                        <p className="fs-6 mt-1">Kart bilgilerinizi, bir
                                                                            sonraki
                                                                            alışverişinizde
                                                                            kullanmak üzere kaydedin.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>
                                                        <b><i
                                                            className="fa-duotone fa-person-carry-box me-2"></i> {deliveryType === '1' ? 'Mağazada' : 'Kapıda'},
                                                            kartla ya
                                                            da nakit ödeyin</b></Accordion.Header>
                                                    <Accordion.Body>
                                                        <ButtonGroup aria-label="Basic example" className="w-50">
                                                            <Button onClick={() => setCodType(0)}
                                                                    variant={codType === 0 ? 'success' : 'outline-secondary'}>
                                                                <i className={`fa-duotone fa-square${codType === 0 ? '-check' : ''} fs-3 mb-2 mt-2`}></i><br/>
                                                                <b>Kapıda Kartla Ödeyin</b>
                                                            </Button>
                                                            <Button onClick={() => setCodType(1)}
                                                                    variant={codType === 1 ? 'success' : 'outline-secondary'}>
                                                                <i className={`fa-duotone fa-square${codType === 1 ? '-check' : ''} fs-3 mb-2 mt-2`}></i><br/>
                                                                <b>Kapıda Nakit Ödeyin</b>
                                                            </Button>
                                                        </ButtonGroup>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xxl-3 ps-0 ">
                                    <div className="position-sticky top-0">
                                        {define.temp.settings.free_delivery_limit > checkout.sub_total && (
                                            <FreeDelivery
                                                free_delivery_limit={define.temp.settings.free_delivery_limit}
                                                total={checkout.sub_total}/>
                                        )
                                        }
                                        <ListGroup className="mb-3">
                                            <ListGroup.Item>
                                                <Form.Check
                                                    type="switch"
                                                    id="zili-calma"
                                                    checked={dontRing}
                                                    onChange={() => setDontRing(!dontRing)}
                                                    label={<><i className="fa-light fa-bell-slash"></i> <b>Zili
                                                        Çalma</b></>}
                                                />
                                                <small className="d-block text-muted">Siparişiniz teslim edilirken telefonla aranırsınız.</small>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Form.Check
                                                    type="switch"
                                                    id="temassiz-teslimat"
                                                    checked={contactless}
                                                    onChange={() => setContactless(!contactless)}
                                                    label={<><i className="fa-regular fa-hand-holding-box"></i> <b>Temassız
                                                        Teslimat</b></>}
                                                />
                                                <small className="d-block text-muted">Siparişiniz kapıya asılır ya da bırakılır.</small>
                                            </ListGroup.Item>
                                        </ListGroup>
                                        {checkout && (
                                            <>

                                                <div className="card">
                                                    <div className="card-header ps-3">
                                                        <h5 className="mb-3 d-inline-block">Sepet Toplamı </h5>
                                                        <small className="float-end">{cart?.cart?.length} Ürün</small>
                                                    </div>
                                                    <div className="card-body p-0">
                                                        <div className="list-group list-group-flush">
                                                            <li className="list-group-item list-group-item-action fw-bold">
                                                                Toplam Tutar
                                                                <span className="float-end">
                                                    <span
                                                        className="text-muted">{formatCurrency(checkout.sub_total)} TL</span>
                                                </span>
                                                            </li>
                                                            {!checkout.no_delivery && (
                                                                <li className="list-group-item list-group-item-action fw-bold">
                                                                    Teslimat Tutarı
                                                                    <span className="float-end">
                                                    <span
                                                        className="text-muted">{checkout?.delivery_charge?.total_delivery_charge ? formatCurrency(checkout.delivery_charge.total_delivery_charge) + ' TL' : '🎉 Ücretsiz'}</span>
                                                </span>
                                                                </li>
                                                            )}
                                                            {activePromoCode && activePromoCode.id && (
                                                                <li className="list-group-item list-group-item-action fw-bold text-success">
                                                                    Kampanya İndirimi
                                                                    <span className="float-end">
                                                    <span
                                                        className="text-success">- {formatCurrency(activePromoCode.discount)} TL</span>
                                                </span>
                                                                </li>
                                                            )}
                                                        </div>

                                                    </div>
                                                    <div className="card-footer bg-theme-light ps-3 pt-2 pb-2">
                                                        <p className="badge bg-success text-white">{formatCurrency(activePromoCode && activePromoCode.id ? (checkout.saved_amount + activePromoCode.discount) : checkout.saved_amount)} TL
                                                            kazancınız var</p><br/>
                                                        <span className="h6 mb-0">Ödenecek Tutar</span>
                                                        <span
                                                            className="float-end theme-color fw-bold fs-6">{formatCurrency(activePromoCode && activePromoCode.id ? (checkout.total_amount - activePromoCode.discount) : checkout.total_amount)} TL</span>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <button className="btn btn-lg bg-theme-color hover-bg-light w-100 mt-4"
                                                onClick={() => handleSubmit()}>Alışverişi
                                            Tamamla <i
                                                className="fa-solid fa-arrow-right-long ms-3"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}
        </>
    )
        ;
};

export default Checkout;
