import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';

import {calculateDiscountPercentage, calculatePercentageDifference, formatCurrency, slugify} from "../../../helpers";
import ProductGrid from "../../../components/boxes/product-grid";
import {Link} from "react-router-dom";
import {Navigation} from "swiper/modules";

const Section = ({items, addItem}) => {
    return (
        <>
            {items && items.length > 0 && items.map((item, index) => (
                <section className="pt-4 mb-4 mt-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="title title-flex">
                                    <div>
                                        <h2>{item.title}</h2>
                                    </div>
                                    <Link to={`/secilenler/${slugify(item.title)}/${item.id}`} className="link theme-color">Tümünü Gör <i className="fa fa-chevron-right f-12"></i></Link>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <img src={item.image_url} className="category-banner" alt="{item.title}"/>
                            </div>
                            <div className="col-lg-9 col-md-9">
                                <div className="product-box-slider-2 position-relative">
                                    <Swiper
                                        spaceBetween={20}
                                        slidesPerView={5}
                                        onSlideChange={() => console.log('slide change')}
                                        onSwiper={(swiper) => console.log(swiper)}
                                        modules={[Navigation]}
                                        navigation={{
                                            prevEl: '.swiper-button-prev',
                                            nextEl: '.swiper-button-next',
                                        }}
                                    >
                                        {item?.products && item?.products?.length > 0 && item?.products.map((product, index) => (
                                            <SwiperSlide key={product.id}>
                                                <ProductGrid product={product} addItem={addItem}/>
                                            </SwiperSlide>
                                        ))}
                                        <div className={`swiper-button swiper-button-prev`}><i
                                            className="fa fa-chevron-left"></i></div>
                                        <div className={`swiper-button swiper-button-next`}><i
                                            className="fa fa-chevron-right"></i></div>
                                    </Swiper>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ))}
        </>
    );
};

export default Section;
