import React, {useEffect, useRef, useState} from "react";
import {Breadcrumb, Dropdown} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import getProducts from "../../redux/actions/getProducts";
import ProductGrid from "../../components/boxes/product-grid";
import Pagination from "../../components/other/pagination";
import {getItemByID} from "../../helpers";

const List = ({define, addItem, type}) => {
    const [currentPage, setPage] = useState(1);
    const [limit, setLimit] = useState(24);
    const [pageCount, setPageCount] = useState(1);
    const [sort, setSort] = useState(false);
    const [sortTitle, setSortTitle] = useState('Önerilen Sıralama');
    const {slug} = useParams();
    const {id} = useParams();
    const [products, setProducts] = useState([]);
    const [category, setCategory] = useState(false);
    const [section, setSection] = useState(false);
    const [search, setSearch] = useState(false);
    const [brand, setBrand] = useState(type === 'brand' ? id : false);
    const [brands, setBrands] = useState([]);
    const [total_product, setTotalProducts] = useState([]);
    const [list, setList] = useState([]);
    const [query, setQuery] = useState(false);
    useEffect(() => {
        let qe = false;
        qe = new URLSearchParams(window.location.search).get('q');
        if (type === 'search') {
            if (qe && qe.length >= 2) {
                setQuery(qe)
            } else {
                location.href = '/'
            }
        }
        addItem('temp.loading', true);
        let calc_offset = (currentPage - 1) * limit;
        let options = {
            latitude: define.perm.latitude,
            longitude: define.perm.longitude,
            limit: limit,
            sort: sort,
            offset: calc_offset,
            category_id: type === 'category' ? id : category,
            section_id: type === 'section' ? id : section,
            brand_id: type === 'brand' ? id : brand,
            search: type === 'search' ? qe : search,
        }
        getProducts(options).then(r => {
            setList(r);
            let page_count = Math.ceil(r.total / limit);
            setPageCount(Array.from({length: page_count}, (_, index) => index + 1));
            addItem('temp.loading', false);
            setProducts(r.data);
            if (r.brands) {
                setBrands(r.brands);
            }
            if (r.category) {
                setCategory(r.category);
            }
            if (r.section) {
                setSection(r.section);
            }
            setTotalProducts(r.total);
        });
    }, [currentPage, sort, brand, query]);

    useEffect(() => {
        if (type === 'brand') {
            setBrand(parseInt(id));
        }
    }, [brand]);

    const handleOrder = (srt, title) => {
        setSort(srt);
        setSortTitle(title);
    }
    return (
        <>
            {products && products.length > 0 && (
                <section className="section-b-space shop-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="card">
                                    <div className="card-header">
                                        {type === 'category' && (
                                            <h4>{category.name}</h4>
                                        )}
                                        {type === 'section' && (
                                            <h4>{section.title}</h4>
                                        )}
                                        {type === 'brand' && (
                                            <h4>{getItemByID(brands, brand).name} markalı ürünler</h4>
                                        )}
                                        {type === 'search' && (
                                            <h4>"{query}" terimini içeren ürünler</h4>
                                        )}
                                        <p className="text-muted">{total_product} ürün</p>
                                    </div>
                                    <div className="card-header">
                                        {category && category.cat_active_childs && category.cat_active_childs.length && (
                                            <>
                                                <h5>Alt Kategoriler</h5>
                                                <ul className="category-list custom-padding custom-height mb-4">
                                                    {category.cat_active_childs.map((brnd, index) => (
                                                        <li>
                                                            <a onClick={() => setBrand(brnd.id === brand ? false : brnd.id)}
                                                               className="d-block cursor-pointer"> {brnd.name}
                                                                <i className="float-end fa fa-chevron-right f-12"></i></a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </>
                                        )}
                                    </div>
                                    <div className="card-body">
                                        <div className="left-box wow fadeInUp">
                                            <div className="shop-left-sidebar p-0">
                                                <div className="back-button">
                                                    <h3><i className="fa-solid fa-arrow-left"></i> Back</h3>
                                                </div>
                                                <div className="accordion custome-accordion" id="accordionExample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingOne">
                                                            <button className="accordion-button" type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseOne" aria-expanded="true"
                                                                    aria-controls="collapseOne">
                                                                <span>Markalar</span>
                                                            </button>
                                                        </h2>
                                                        <div id="collapseOne"
                                                             className="accordion-collapse collapse show"
                                                             aria-labelledby="headingOne">
                                                            <div className="accordion-body">
                                                                <ul className="category-list custom-padding custom-height">
                                                                    <li>
                                                                        <a onClick={() => setBrand(false)}
                                                                           className="d-block cursor-pointer">
                                                                            <i className={`fa-regular me-2 ${!brand ? 'theme-color fa-square-check' : 'fa-square'}`}></i>
                                                                            Tüm Markalar
                                                                            <i className="float-end fa fa-chevron-right f-12"></i></a>
                                                                    </li>
                                                                    {brands && brands?.length > 0 && brands.map((brnd, index) => (
                                                                        <li>
                                                                            <a onClick={() => setBrand(brnd.id === brand ? false : brnd.id)}
                                                                               className="d-block cursor-pointer"><i
                                                                                className={`fa-regular me-2 ${brand === brnd.id ? 'theme-color fa-square-check' : 'fa-square'}`}></i> {brnd.name}
                                                                                <i className="float-end fa fa-chevron-right f-12"></i></a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-9">
                                <div className="show-button">
                                    <div className="filter-button-group">
                                        <div className="filter-button d-inline-block d-lg-none">
                                            <a><i className="fa-solid fa-filter"></i> Filter Menu</a>
                                        </div>
                                    </div>

                                    <div className="top-filter-menu">
                                        <div className="category-dropdown ms-auto">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                    <i className="fa-solid fa-arrow-down-arrow-up"></i> {sortTitle} <i
                                                    className="ms-3 fa-light fa-chevron-down"></i>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={() => handleOrder(false, 'Önerilen Sıralama')}>Önerilen
                                                        Sıralama</Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => handleOrder('low', 'Önce En Düşük Fiyat')}>Önce
                                                        En Düşük Fiyat</Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => handleOrder('high', 'Önce En Yüksek Fiyat')}>Önce
                                                        En Yüksek Fiyat</Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => handleOrder('new', 'Önce En Yeni Ürün')}>Önce En
                                                        Yeni Ürün</Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => handleOrder('old', 'Önce En Eski Ürün')}>Önce En
                                                        Eski Ürün</Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => handleOrder('discount', 'İndirim Oranına Göre')}>İndirim
                                                        Oranına Göre</Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => handleOrder('popular', 'Çok Satanlar')}>Çok
                                                        Satanlar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>

                                <div className="row product-list-section">
                                    {products && products?.length > 0 && products.map((product, index) => (
                                        <div className="col-lg-3 col-md-3 mb-4">
                                            <ProductGrid product={product} addItem={addItem}/>
                                        </div>
                                    ))}
                                </div>
                                <Pagination currentPage={currentPage} pageCount={pageCount} setPage={setPage}/>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {!products && (
                <section className="py-4 my-4">
                    <div className="container text-center">
                        <img src="./assets/images/no-product.webp" height={300} alt=""/>
                        <p className="h4">Aradığınız kriterlerde hiç ürün bulunamamıştır</p>
                        <Link to={'/'} className="btn bg-theme-light theme-color mt-3"><i
                            className="fa fa-home"></i> Anasayfaya Dönün</Link>
                    </div>
                </section>
            )}
        </>
    )
}

export default List;
