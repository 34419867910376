import React, {useEffect, useState} from 'react';
import {BrowserRouter, Link, Route, Routes} from 'react-router-dom';
import Index from './screens/home';
import Layout from "./screens/layout";
import getSettings from "./redux/actions/getSettings";
import Detail from "./screens/product/detail";
import List from "./screens/product/list";
import Login from "./screens/auth/login";
import Cart from "./screens/cart";
import getCart from "./redux/actions/getCart";
import Checkout from "./screens/cart/checkout";
import {user} from "./helpers";
import Middleware from "./utility/middleware";
import Orders from "./screens/user/orders";
import Favorites from "./screens/user/favorites";
import Addresses from "./screens/user/addresses";
import Loading from "./components/other/loading";
import Register from "./screens/auth/register";

function App() {

    const [define, setDefine] = useState({
        "perm": localStorage.getItem('perm') ? JSON.parse(localStorage.getItem('perm')) : {
            city: '',
            city_id: '',
            district: '',
            district_id: '',
            neighbor: '',
            neighbor_id: '',
            latitude: '',
            longitude: '',
            is_settings: true
        },
        "temp": {
            connection: false,
            failed_message: false,
            refresh_cart: true,
            loading: true,
            settings: [],
            cart: [],
            checkout: [],
            delivery_type: '0'
        }
    });
    const addItem = (path, newValue) => {
        setDefine(prevState => {
            const newState = {...prevState};
            let currentState = newState;

            const keys = path.split('.');
            for (let i = 0; i < keys.length - 1; i++) {
                if (!currentState[keys[i]]) {
                    // Eğer ara düğüm yoksa (undefined veya null ise), boş bir nesne oluştur
                    currentState[keys[i]] = {};
                }
                currentState = currentState[keys[i]];
            }

            currentState[keys[keys.length - 1]] = newValue;

            return newState;
        });
    };
    useEffect(() => {
        const defined = JSON.stringify(define.perm);
        localStorage.setItem('perm', defined);
    }, [define]);

    useEffect(() => {
        if (define.temp.refresh_cart && define.temp.connection) {
            getCart(define.perm.latitude, define.perm.longitude).then(r => {
                addItem('temp.cart', r.data);
            })
            getCart(define.perm.latitude, define.perm.longitude, 1, define.temp.delivery_type).then(r => {
                addItem('temp.checkout', r.data);
                addItem('temp.loading', false);
            })
            addItem('temp.refresh_cart', false);
        }
    }, [define.temp.refresh_cart, define.temp.connection]);

    useEffect(() => {
        getSettings().then(r => {
            addItem('temp.settings', r.data);
            addItem('temp.connection', true);
            if (!define.perm.longitude) {
                addItem('perm.latitude', r.data.map_latitude);
                addItem('perm.longitude', r.data.map_longitude);
            }
            addItem('term.loading', false)
        }).catch(e => {
            addItem('temp.loading', false);
            addItem('temp.connection', false);
            addItem('temp.failed_message', 'not_connection');
            console.log(e)
        })
    }, []);

    useEffect(() => {
        if (define.temp.loading) {
            setTimeout(() => {
                addItem('temp.loading', false);
            }, 4000)
        }
    }, [define.temp.loading]);
    return (
        <BrowserRouter>
            {define.temp.connection ? (
                <div>
                    <Routes>
                        <Route path="/" element={
                            <Layout addItem={addItem} define={define}>
                                <Index addItem={addItem} define={define}/>
                            </Layout>
                        }/>
                        <Route path="/giris" element={
                            <Layout addItem={addItem} define={define}>
                                <Login addItem={addItem} define={define}/>
                            </Layout>
                        }/>
                        <Route path="/kayit-ol" element={
                            <Layout addItem={addItem} define={define}>
                                <Register addItem={addItem} define={define}/>
                            </Layout>
                        }/>
                        <Route path="/kategori/:slug/:id" element={
                            <Layout addItem={addItem} define={define}>
                                <List addItem={addItem} define={define} type="category"/>
                            </Layout>
                        }/>
                        <Route path="/arama" element={
                            <Layout addItem={addItem} define={define}>
                                <List addItem={addItem} define={define} type="search"/>
                            </Layout>
                        }/>
                        <Route path="/secilenler/:slug/:id" element={
                            <Layout addItem={addItem} define={define}>
                                <List addItem={addItem} define={define} type="section"/>
                            </Layout>
                        }/>
                        <Route path="/marka/:slug/:id" element={
                            <Layout addItem={addItem} define={define}>
                                <List addItem={addItem} define={define} type="brand"/>
                            </Layout>
                        }/>
                        <Route path="/urun/:urun_id" element={
                            <Layout addItem={addItem} define={define}>
                                <Detail addItem={addItem} define={define}/>
                            </Layout>
                        }/>
                        <Route path="/sepet" element={
                            <Layout addItem={addItem} define={define}>
                                <Cart addItem={addItem} define={define}/>
                            </Layout>
                        }/>
                        <Route path="/alisverisi-tamamla" element={
                            <Layout addItem={addItem} define={define}>
                                <Middleware Component={Checkout} addItem={addItem} define={define}/>
                            </Layout>
                        }/>
                        <Route path="/siparisler" element={
                            <Layout addItem={addItem} define={define}>
                                <Middleware Component={Orders} addItem={addItem} define={define}/>
                            </Layout>
                        }/>
                        <Route path="/begenilenler" element={
                            <Layout addItem={addItem} define={define}>
                                <Middleware Component={Favorites} addItem={addItem} define={define}/>
                            </Layout>
                        }/>
                        <Route path="/adresler" element={
                            <Layout addItem={addItem} define={define}>
                                <Middleware Component={Addresses} addItem={addItem} define={define}/>
                            </Layout>
                        }/>
                        <Route path="*" status={404} element={
                            <Layout addItem={addItem} define={define}>
                                <section className="py-4 my-4">
                                    <div className="container text-center">
                                        <img src="./assets/images/no-page.webp" height={300} alt=""/>
                                        <p className="h4">Aradığınız sayfaya şuan ulaşılamıyor.</p>
                                        <p className="h5 text-muted">Sayfa taşınmış ya da silinmiş olabilir.</p>
                                        <p className="text-muted">Bunun teknik bir hata olduğunu düşünüyorsanız, <br/>iletişim
                                            sayfamızdan bize bildirebilirsiniz.</p>
                                        <Link to={'/'} className="btn bg-theme-light theme-color mt-3"><i
                                            className="fa fa-home"></i> Anasayfaya Dönün</Link>
                                    </div>
                                </section>
                            </Layout>
                        }/>
                    </Routes>
                </div>
            ) : (
                <Routes>
                    <Route path="*" element={
                        <Loading show={true} define={define} />
                    }/>
                </Routes>
            )}

        </BrowserRouter>
    );
}

export default App;
