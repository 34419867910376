import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Sidebar from "./partials/sidebar";
import getOrders from "../../redux/actions/getOrders";
import {Tab, Tabs, Accordion} from "react-bootstrap";
import {formatCurrency, formatDate, getOrderStatus} from "../../helpers";
import Pagination from "../../components/other/pagination";
import getFavorites from "../../redux/actions/getFavorites";
import ProductGrid from "../../components/boxes/product-grid";
import getAddresses from "../../redux/actions/getAddresses";

const Addresses = ({define, addItem}) => {
    const [addresses, setAddresses] = useState(10);
    const [perPage, setPerPage] = useState(10);
    const [defaultAddress, setDefault] = useState([]);

    useEffect(() => {
        addItem('temp.loading', true);
        getAddresses().then(r => {
            if(r.data){
                setAddresses(r.data);
                if (r.data.find((item) => item.is_default === 1)) {
                    if(setDefault){
                        setDefault(r.data.find((item) => item.is_default === 1))
                    }
                }
            }
            addItem('temp.loading', false);
        })
    }, []);
    return (
        <>
            <section className="breadscrumb-section p-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadscrumb-contain">
                                <h2>Adresler</h2>
                                <nav>
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to={'/'}>
                                                <i className="fa-solid fa-house"></i>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">Adresler</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="user-dashboard-section section-b-space">
                <div className="container">
                    <div className="row">
                        <Sidebar define={define} addItem={addItem} active="addresses"/>
                        <div className="col-lg-9 col-md-9">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="mb-4 d-inline-block">Adresleriniz</h4>
                                    <button className="btn bg-theme-light theme-color hover-bg-theme-color float-end"><i className="fa fa-plus"></i> Adres Ekle</button>
                                </div>
                                <div className="card-body">
                                    <Addresses addItem={addItem} define={define} setDefault={setDefault} defaultAddress={defaultAddress} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Addresses;
