import React, {useEffect, useState} from 'react';
import {calculateDiscountPercentage, formatCurrency, user} from "../../helpers";
import AddCart from "../../components/addCart";
import removeCart from "../../redux/actions/removeCart";
import {Link} from "react-router-dom";
import FreeDelivery from "../../components/addCart/free-delivery";

const Index = ({define, addItem}) => {
    const [active, setActive] = useState(false);
    const [cart, setCart] = useState([]);
    const [checkout, setCheckout] = useState([]);
    useEffect(() => {
        addItem('temp.loading', true);
        setCart(define.temp.cart);
        setCheckout(define.temp.checkout);
        setTimeout(() => {
            addItem('temp.loading', false);
        }, 3000)
    }, [define.temp.cart, define.temp.checkout]);

    const handleRemove = (product) => {
        addItem('temp.loading', true);
        removeCart(product.product_variant_id).then(r => {
            addItem('temp.loading', false);
            addItem('temp.refresh_cart', true);
        });
    }
    return (
        <>
            <section className="breadscrumb-section p-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadscrumb-contain">
                                <h2>Sepetim</h2>
                                <nav>
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to={'/'}>
                                                <i className="fa-solid fa-house"></i>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">Sepetim</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cart-section section-b-space">
                <div className="container">
                    <div className="row g-sm-5 g-3">
                        <div className={`${!cart ? ' col-xxl-12' : 'col-xxl-9'}`}>
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="mb-3">Sepet İçeriği</h5>
                                </div>
                                <div className="card-body p-0">
                                    <div className="table-responsive-xl">
                                        <table className="table-striped table mb-0 all-item-middle">
                                            {cart && (
                                                <thead>
                                                <tr>
                                                    <th>Ürün</th>
                                                    <th>Fiyat</th>
                                                    <th width={150}>Adet</th>
                                                    <th>Toplam</th>
                                                    <th width="100" className="text-center text-danger">Kaldır</th>
                                                </tr>
                                                </thead>
                                            )}
                                            <tbody>
                                            {cart && cart.cart && cart.cart.length > 0 && cart.cart.map((product, index) => (
                                                <tr>
                                                    <td>
                                                        <Link to={`/urun/${product.slug}`}>
                                                            <img src={product.image_url} width={60} height={60}
                                                                 className="img-radius float-start" alt=""/>
                                                            <div className="float-start ps-3 text-muted fw-bold">
                                                                {product.name}<br/>
                                                                <small
                                                                    className="text-muted f-12">{product.qty} {product.unit}</small>
                                                            </div>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        {product.discounted_price > 0 && (
                                                            <span
                                                                className="badge bg-theme-light theme-color">%{calculateDiscountPercentage(product.price, product.discounted_price)} İndirim</span>
                                                        )}
                                                        {product.discounted_price > 0 && (
                                                            <small className="d-block">
                                                                <del
                                                                    className="text-muted">{formatCurrency(product.price)} TL
                                                                </del>
                                                            </small>
                                                        )}
                                                        <span
                                                            className="theme-color fw-bold">{formatCurrency(product.discounted_price > 0 ? product.discounted_price : product.price)} TL</span>
                                                    </td>
                                                    <td className="position-relative">
                                                        <div className="position relative cart-add-button-table">
                                                            <AddCart define={define} active={active}
                                                                     setActive={setActive} addItem={addItem}
                                                                     product={product} cart={true} value={product.qty}/>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span
                                                            className="theme-color fw-bold">{formatCurrency(product.discounted_price > 0 ? (product.discounted_price * product.qty) : (product.price * product.qty))} TL</span>
                                                    </td>
                                                    <td className="text-center">
                                                        <button className="btn btn-danger btn-sm"
                                                                onClick={() => handleRemove(product)}><i
                                                            className="fa fa-trash-alt"></i></button>
                                                    </td>
                                                </tr>
                                            ))}

                                            {!cart && (
                                                <tr className="bg-white">
                                                    <td colspan={6} className="text-center td-bg-white py-4">
                                                        <img src="./assets/images/no-cart.png" height={200} alt=""/>
                                                        <h5 className="mt-4 mb-4">Sepetinizde hiç ürün yok!</h5>
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-3 ps-0">
                            {checkout && (
                                <>
                                    {define.temp.settings.free_delivery_limit > checkout.sub_total && (
                                        <FreeDelivery free_delivery_limit={define.temp.settings.free_delivery_limit}
                                                      total={checkout.sub_total}/>
                                    )}
                                    <div className="card">
                                        <div className="card-header ps-3">
                                            <h5 className="mb-3 d-inline-block">Sepet Toplamı </h5>
                                            <small className="float-end">{cart?.cart?.length} Ürün</small>
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="list-group list-group-flush">
                                                <li className="list-group-item list-group-item-action fw-bold">
                                                    Ara Toplam
                                                    <span className="float-end">
                                                    <span
                                                        className="text-muted">{formatCurrency(checkout.sub_total)} TL</span>
                                                </span>
                                                </li>
                                                <li className="list-group-item list-group-item-action fw-bold">
                                                    Teslimat Ücreti
                                                    <span className="float-end">
                                                    <span
                                                        className="text-muted">{checkout?.delivery_charge?.total_delivery_charge ? formatCurrency(checkout.delivery_charge.total_delivery_charge) + ' TL' : '🎉 Ücretsiz'}</span>
                                                </span>
                                                </li>
                                            </div>

                                        </div>
                                        <div className="card-footer bg-theme-light ps-3 pt-2 pb-2">
                                            {checkout.saved_amount > 0 && (
                                                <>
                                                    <p className="badge bg-success text-white">{formatCurrency(checkout.saved_amount)} TL
                                                        kazancınız var</p><br/>
                                                </>
                                            )}

                                            <span className="h6 mb-0">Ödenecek Tutar</span>
                                            <span
                                                className="float-end theme-color fw-bold fs-6">{formatCurrency(checkout.total_amount)} TL</span>
                                        </div>
                                        {checkout.sub_total < define.temp.settings.min_order_amount && (
                                            <div className="card-footer d-flex ">
                                                <i className="fa-duotone fa-circle-info flex-grow-1 text-danger fs-5 align-self-center me-2"></i>
                                                <p className="text-danger small p-0 m-0">
                                                    Sipariş verebilmeniz için sepet tutarınız <b>en
                                                    az {formatCurrency(define.temp.settings.min_order_amount)} TL ve
                                                    üzerinde</b> olmalıdır.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    <Link to={'/alisverisi-tamamla'}
                                          disabled={checkout.sub_total < define.temp.settings.min_order_amount}
                                          className={`btn btn-lg bg-theme-color hover-bg-light w-100 mt-4${checkout.sub_total < define.temp.settings.min_order_amount ? ' disabled' : ''}`}>Alışverişi
                                        Tamamla <i
                                            className="fa-solid fa-arrow-right-long ms-3"></i></Link>
                                </>
                            )}


                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Index;
