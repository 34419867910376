import React, {useEffect} from 'react';

const Pagination = ({pageCount, currentPage, setPage}) => {
    useEffect(() => {
        console.log(pageCount)
    }, []);
    return (
        <>
            {pageCount.length > 1 && (
                <nav className="custome-pagination">
                    <ul className="pagination justify-content-center">
                        {currentPage > 1 && (
                            <li className="page-item cursor-pointer" onClick={() => setPage(currentPage - 1)}>
                                <a className="page-link" href="#" tabIndex="-1"
                                   aria-disabled="true">
                                    <i className="fa-solid fa-angles-left"></i>
                                </a>
                            </li>
                        )}
                        {pageCount.length > 1 && pageCount.map((page, index) => (
                            <li className={`page-item cursor-pointer ${page === currentPage ? 'active' : ''}`}
                                onClick={() => setPage(page)}>
                                <a className="page-link">{page}</a>
                            </li>
                        ))}
                        {currentPage < pageCount.length && (
                            <li className="page-item cursor-pointer" onClick={() => setPage(currentPage + 1)}>
                                <a className="page-link">
                                    <i className="fa-solid fa-angles-right"></i>
                                </a>
                            </li>
                        )}
                    </ul>
                </nav>
            )}
        </>
    );
};
export default Pagination;
