import React from 'react';
import {slugify} from "../helpers";
import {Link} from "react-router-dom";

const Footer = ({define, addItem}) => {
    return (
        <div>
            <section className="service-section section-b-space">
                <div className="container">
                    <div className="row g-3 ">
                        <div className="col">
                            <div className="service-contain-2">
                                <img src={'assets/svg/product.svg'} alt="" className="icon-width"/>
                                <div className="service-detail">
                                    <h3 className="h5">Her zaman taptaze!</h3>
                                    <h6 className="text-content f-14">Taze, günlük sebze ve meyveler</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="service-contain-2">
                                <img src="../assets/svg/market.svg" className="blur-up lazyloaded icon-width" alt=""/>
                                <div className="service-detail">
                                    <h3 className="h5">En iyi fiyatlar!</h3>
                                    <h6 className="text-content f-14">Bir gün değil, her gün indirim!</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="service-contain-2">
                                <img src="../assets/svg/discount.svg" className="blur-up lazyloaded icon-width" alt=""/>
                                <div className="service-detail">
                                    <h3 className="h5">Ödeme İmkanları</h3>
                                    <h6 className="text-content f-14">İster kredi kartı, ister kapıda!</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="service-contain-2">
                                <img src="../assets/svg/delivery.svg" className="blur-up lazyloaded icon-width" alt=""/>
                                <div className="service-detail">
                                    <h3 className="h5">Getirme Bedeli Yok!</h3>
                                    <h6 className="text-content f-14">100 TL ve üzerinde geçerlidir.</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="section-t-space mt-4">
                <div className="container">

                    <div className="main-footer section-b-space section-t-space">
                        <div className="row g-md-4 g-3">
                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="footer-logo">
                                    <div className="theme-logo">
                                        <a href="index.html">
                                            <img src="../assets/images/logo.png" alt=""/>
                                        </a>
                                    </div>

                                    <div className="footer-logo-contain">
                                        <p>{define.temp?.settings?.web_settings?.copyright_details}</p>

                                        <ul className="address mt-3">
                                            <li>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     className="feather feather-home">
                                                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                                    <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                                </svg>
                                                <a href="#">{define.temp?.settings?.store_address}</a>
                                            </li>
                                            <li>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     className="feather feather-mail">
                                                    <path
                                                        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                                    <polyline points="22,6 12,13 2,6"></polyline>
                                                </svg>
                                                <a href="#">{define.temp?.settings?.support_email}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                                <div className="footer-title">
                                    <h4>Kategoriler</h4>
                                </div>

                                <div className="footer-contain">
                                    <ul>
                                        {define.temp.settings && define.temp.settings.categories && define.temp.settings.categories.length && define.temp.settings.categories.map((category) => (
                                            <li>
                                                <Link to={`/kategori/${slugify(category.name)}/${category.id}`} className="text-content">{category.name}</Link>
                                            </li>
                                        ))}

                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl col-lg-2 col-sm-3">
                                <div className="footer-title">
                                    <h4>Kısayıllar</h4>
                                </div>

                                <div className="footer-contain">
                                    <ul>
                                        <li>
                                            <Link to={"/"} className="text-content">Anasayfa</Link>
                                        </li>
                                        <li>
                                            <Link to={"/sayfa/hakkimizda"} className="text-content">Şahmar</Link>
                                        </li>
                                        <li>
                                            <Link to={"/sayfa/sartlar-kosullar"} className="text-content">Şartlar ve
                                                Koşullar</Link>
                                        </li>
                                        <li>
                                            <Link to={"/sayfa/gizlilik-politikasi"} className="text-content">Gizlilik
                                                Politikası</Link>
                                        </li>
                                        <li>
                                            <Link to={"/sayfa/iade-degisim-politikasi"} className="text-content">İade ve
                                                Değişim Politikası</Link>
                                        </li>
                                        <li>
                                            <Link to={"/sayfa/dagitim-politikasi"} className="text-content">Dağıtım
                                                Politikası</Link>
                                        </li>
                                        <li>
                                            <Link to={"/sayfa/iletisim"} className="text-content">İletişim Bİlgileri</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-2 col-sm-3">
                                <div className="footer-title">
                                    <h4>Kullanıcı Merkezi</h4>
                                </div>

                                <div className="footer-contain">
                                    <ul>
                                        <li>
                                            <Link to={"/siparisler"} className="text-content">Siparişleriniz</Link>
                                        </li>
                                        <li>
                                            <Link to={"/adresler"} className="text-content">Adresleriniz</Link>
                                        </li>
                                        <li>
                                            <Link to={"/begenilenler"} className="text-content">Beğendikleriniz</Link>
                                        </li>
                                        <li>
                                            <Link to={"/profil-bilgileri"} className="text-content">Bilgileriniz</Link>
                                        </li>
                                        <li>
                                            <Link to={"/sayfa/sikca-sorulanlar"} className="text-content">Sıkça Sorulanlar</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="footer-title">
                                    <h4>Bize Ulaşın</h4>
                                </div>

                                <div className="footer-contact">
                                    <ul>
                                        <li>
                                            <div className="footer-number">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     className="feather feather-phone">
                                                    <path
                                                        d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                                                </svg>
                                                <div className="contact-number">
                                                    <h6 className="text-content">Müşteri Destek Hattı</h6>
                                                    <h5>{define.temp?.settings?.support_number}</h5>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="footer-number">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     className="feather feather-mail">
                                                    <path
                                                        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                                    <polyline points="22,6 12,13 2,6"></polyline>
                                                </svg>
                                                <div className="contact-number">
                                                    <h6 className="text-content">E-Posta Adresi :</h6>
                                                    <h5>{define.temp?.settings?.support_email}</h5>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="social-app">
                                            <h5 className="mb-4 text-content">Uygulamamızı İndirin</h5>
                                            <ul>
                                                <li className="mb-0">
                                                    <a href={define.temp?.settings?.web_settings?.android_app_url}
                                                       target="_blank">
                                                        <img src="../assets/images/playstore.svg"
                                                             alt="Şahmar android uygulamasını indirmek için tıklayın."/>
                                                    </a>
                                                </li>
                                                <li className="mb-0">
                                                    <a href={define.temp?.settings?.web_settings?.ios_app_url}
                                                       target="_blank">
                                                        <img src="../assets/images/appstore.svg"
                                                             alt="Şahmar iOS uygulamasını indirmek için tıklayın."/>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <img src="../assets/images/payment/1.png" className="mt-4" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sub-footer section-small-space">
                        <div className="reserve">
                            <h6 className="text-content">©Copyright 2024 - Tüm hakları Şahmar'a aittir.</h6>
                        </div>


                        <div className="social-link footer-social">
                            <ul>
                                {define.temp?.settings?.social_media && define.temp?.settings?.social_media.length && define.temp?.settings?.social_media.map((social) => (
                                    <li>
                                        <a href={social.link} target="_blank">
                                            <i className={`fa-brands ${social.icon}`}></i>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>


            <div className="modal location-modal fade theme-modal" id="locationModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Teslimat Adresi Seçin</h5>
                            <p className="mt-1 text-content">Size özel teklifler ve ürünler için teslimat adresinizi
                                seçin.</p>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="fa-solid fa-xmark"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="location-list">
                                <div className="disabled-box">
                                    <h6>Lokasyon Seçin</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="theme-option">
                <div className="back-to-top">
                    <a id="back-to-top" href="#">
                        <i className="fas fa-chevron-up"></i>
                    </a>
                </div>
            </div>
        </div>
    );
};
export default Footer;
