import React, {useEffect, useState} from 'react';
import {formatCurrency} from "../../helpers";
import {Link} from "react-router-dom";
import getCart from "../../redux/actions/getCart";
import removeCart from "../../redux/actions/removeCart";
import FreeDelivery from "./free-delivery";

const Cart = ({define, addItem}) => {
    const [loader, setLoader] = useState(false);
    const [cart, setCart] = useState([]);
    const [showCart, setShowCart] = useState(false);
    useEffect(() => {
        setCart(define.temp.cart);
        addItem('temp.loading', false);
    }, [define.temp.cart]);

    const handleRemoveItem = (id) => {
        setLoader(true);
        removeCart(id).then(r => {
            addItem('temp.refresh_cart', true);
            setLoader(false);
        });
    }
    return (
        <>
            <li className="right-side">
                <div className="location-box cart-box">
                    <button className="btn location-button" onBlur={() => setTimeout(() => {
                        setShowCart(false)
                    }, 500)}
                            onClick={() => setShowCart(!showCart)}>
                                                        <span className="location-arrow">
                                                          <i className="fa-light fa-cart-shopping theme-color"></i>
                                                        </span>
                        <span
                            className="locat-name text-left text-muted w-200">Sepetim<br/>
                                                        <small>{formatCurrency(cart && cart.sub_total ? cart.sub_total : 0)} TL</small></span>
                        <i className="fa-regular fa-chevron-down me-2"></i>
                    </button>
                </div>
            </li>
            {showCart && (
                <li className="right-side cart-icona">
                    <div className="onhover-dropdown header-badge">
                        <div className="onhover-div cart-onhover-div">
                            <ul className={`cart-list ${loader ? 'loading' : ''}`}>
                                {loader && (
                                    <div className="loader"><i className="fa-solid fa-sync fa-spin"></i></div>
                                )}
                                {!loader && cart && cart.cart && cart.cart.length > 0 && cart.cart.map((product, index) => (
                                    <li className="product-box-contain">
                                        <div className="drop-cart">
                                            <Link to={`/urun/${product.slug}`}
                                                  className="drop-image">
                                                <img
                                                    src={product.image_url}
                                                    alt="" className="p-0 img-radius"/>
                                            </Link>

                                            <div className="drop-contain">

                                                <Link to={`/urun/${product.slug}`}>
                                                    <h5>{product.name}</h5>
                                                </Link>
                                                {product.discounted_price > 0 && (
                                                    <span className="theme-color"><i
                                                        className="fa-duotone fa-badge-percent"></i>  İndirimli</span>
                                                )}
                                                <h6>
                                                    <span>{product.qty} {product.unit} x</span> {formatCurrency(product.discounted_price > 0 ? product.discounted_price : product.price)} TL
                                                </h6>
                                                <button
                                                    onClick={() => handleRemoveItem(product.product_variant_id)}
                                                    className="close-button close_button float-end">
                                                    <i className="fas fa-trash-alt"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                                {!loader && !cart && (
                                    <div className="text-center w-100">
                                        <img src="./assets/images/no-cart.png" height="200" className="mb-3" alt=""/>
                                        <h5>Sepetinizde hiç ürün yok!</h5>
                                    </div>
                                )}
                            </ul>
                            {cart && define.temp.settings.free_delivery_limit > cart.sub_total && (
                                <FreeDelivery free_delivery_limit={define.temp.settings.free_delivery_limit}
                                              total={cart.sub_total} className="mt-3"/>
                            )}
                            <div className="button-group mt-2">
                                <Link to={'/sepet'} className="btn btn-sm cart-button theme-bg-color w-100 btn-block
                                                    text-white">Sepete Git</Link>
                            </div>
                        </div>
                    </div>
                </li>
            )}
        </>
    );
};
export default Cart;
