import React from 'react';

const Plus = ({cartValue, handleActive, handlePlus, handleMinus, cart}) => {
    return (
        <div className="cart-box">
            <div className="add-to-cart-box">
                <button className="btn btn-add-cart addcart-button" onClick={() => handleActive()}>
                                                                <span className="add-icon bg-theme">
                                                                    <i className="fa-solid fa-plus"></i>
                                                                </span>
                </button>
                <div className={`cart_qty qty-box ${cart ? 'qty-box-active' : ''}`}>
                    <div className="input-group">
                        <button type="button" className={`qty-left-minus ${cartValue === 1 ? 'bg-danger' : ''}`}
                                onClick={() => handleMinus()}
                                data-field="">
                            <i className={`fa ${cartValue === 1 ? 'fa-trash-alt' : 'fa-minus'}`}
                               aria-hidden="true"></i>
                        </button>
                        <input
                            className="form-control input-number qty-input"
                            type="text"
                            readOnly
                            name="quantity" value={cartValue}/>
                        <button type="button" className="qty-right-plus"
                                onClick={() => handlePlus()} data-field="">
                            <i className="fa fa-plus"
                               aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Plus;
