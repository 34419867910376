import axiosInstance from "../helpers/axios";

export const getSettings = async () => {
    try {
        let url = '/customer/settings?is_web_setting=1';
        const response = await axiosInstance.get(url); // API'den konumları almak için GET isteği
        return response.data; // İstenen veri buradan dönecektir
    } catch (error) {
        // Hata durumunda burada hata işleme veya yönetimi yapılabilir
        console.error('Error fetching locations:', error);
        throw error; // Hata durumunda çağrıyı yapan yere hatayı iletmek için throw kullanılabilir
    }
};

export default getSettings;
