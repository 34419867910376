import React, {useEffect, useState} from 'react';
import getLocations from "../redux/actions/getLocations";
import LocationModal from "../components/modals/location-modal";
import {Link, useNavigate} from "react-router-dom";
import getCart from "../redux/actions/getCart";
import Cart from "../components/addCart/cart";
import {handleLogout, user} from "../helpers";


const Header = ({define, addItem}) => {
    const navigate = useNavigate();
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState(null);
    useEffect(() => {
        setSearchQuery(new URLSearchParams(window.location.search).get('q'));
        if (!define.perm.city && !define.perm.district && !define.perm.neighbor && !define.perm.latitude && !define.perm.longitude) {
            setShowLocationModal(true)
        }
    }, []);

    const handleSearchEnter = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        if(!searchQuery || searchQuery.length < 2){
            return false;
        } else {
            setSearchQuery(searchQuery);
            location.href = '/arama?q=' + searchQuery;
        }
    }

    return (
        <div>
            <LocationModal show={showLocationModal} setShow={setShowLocationModal} define={define} addItem={addItem}/>
            <header className="pb-0">
                <div className="top-nav top-header sticky-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="navbar-top">
                                    <button className="navbar-toggler d-xl-none d-inline navbar-menu-button"
                                            type="button" data-bs-toggle="offcanvas" data-bs-target="#primaryMenu">
                    <span className="navbar-toggler-icon">
                      <i className="fa-solid fa-bars"></i>
                    </span>
                                    </button>
                                    <Link className="web-logo nav-logo" to={'/'}>
                                        <img src="../assets/images/logo.png" className="img-fluid" alt=""/>
                                    </Link>

                                    <div className="middle-box">
                                        <div className="location-box">
                                            <button className="btn location-button" data-bs-toggle="modal"
                                                    data-bs-target="#locationModal"
                                                    onClick={() => setShowLocationModal(true)}>
                        <span className="location-arrow text-center">
                          <i className="fa-light fa-location-dot theme-color"></i>
                        </span>
                                                {define.perm && define.perm.city && define.perm.district && define.perm.neighbor && define.perm.latitude && define.perm.longitude ? (
                                                    <span
                                                        className="locat-name locat-name-active"><b>{define.perm.city}</b>{define.perm.district} {define.perm.neighbor}</span>
                                                ) : (
                                                    <span className="locat-name">Teslimat Adresi Seçin</span>
                                                )}
                                                <i className="fa-solid fa-angle-down"></i>
                                            </button>
                                        </div>

                                        <form className="search-box" onSubmit={handleSearch}>
                                            <div className="input-group">
                                                <input type="search" className="form-control"
                                                       placeholder="Ürün ya da kategori arayın..."
                                                       aria-label="Recipient's username"
                                                       value={searchQuery}
                                                       onChange={(q) => setSearchQuery(q.target.value)}
                                                       aria-describedby="button-addon2"/>
                                                <button className="btn" onClick={handleSearch} type="submit" id="button-addon2">
                                                    <i className="fas fa-magnifying-glass"></i>
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                    <div className="rightside-box">
                                        <div className="search-full">
                                            <div className="input-group">
                        <span className="input-group-text">
                          <i className="fas fa-magnifying-glass font-light"></i>
                        </span>
                                                <input type="text" className="form-control search-type" value={searchQuery}
                                                       onChange={(q) => setSearchQuery(q.target.value)}
                                                       placeholder="Search here.."/>
                                                <span className="input-group-text close-search">
                          <i className="fas fa-times font-light"></i>
                        </span>
                                            </div>
                                        </div>
                                        <ul className="right-side-menu">
                                            <li className="right-side">
                                                <div className="delivery-login-box">
                                                    <div className="delivery-icon">
                                                        <div className="search-box">
                                                            <i className="fas fa-magnifying-glass"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            {user() && (
                                                <li className="right-side onhover-dropdown me-3">
                                                    <div className="delivery-login-box ">
                                                        <div className="delivery-icon border-0 mt-0">
                                                            <img src={user().profile} width={40}
                                                                 className="user-profile-img" alt=""/>
                                                        </div>
                                                        <div className="delivery-detail">
                                                            <small>Merhaba,</small>
                                                            <p>{user().name}</p>
                                                        </div>
                                                    </div>

                                                    <div className="onhover-div login-menus onhover-div-login">
                                                        <ul className="user-box-name">
                                                            <li className="product-box-contain">
                                                                <i></i>
                                                                <Link to={'/siparisler'}><i
                                                                    className="fa-duotone fa-bags-shopping"></i> Siparişler</Link>
                                                            </li>
                                                            <li className="product-box-contain">
                                                                <Link to={'/begenilenler'}><i
                                                                    className="fa-duotone fa-heart"></i> Beğenilenler
                                                                </Link>
                                                            </li>
                                                            <li className="product-box-contain">
                                                                <Link to={'/adresler'}><i
                                                                    className="fa-duotone fa-map-location-dot"></i> Adresler</Link>
                                                            </li>

                                                            <li className="product-box-contain">
                                                                <Link to={'/profil-bilgileri'}><i
                                                                    className="fa-duotone fa-user-pen"></i> Profil
                                                                    Bilgileri</Link>
                                                            </li>
                                                            <li className="seperator"></li>
                                                            <li className="product-box-contain text-danger cursor-pointer"
                                                                onClick={() => {
                                                                    handleLogout()
                                                                }}>
                                                                <a className="text-danger"><i
                                                                    className="fa-duotone fa-right-from-bracket"></i> Çıkış
                                                                    Yap</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            )}
                                            {!user() && (
                                                <li className="right-side onhover-dropdown ">
                                                    <div className="delivery-login-box">
                                                        <Link to={'/giris'} className="delivery-icon cursor-pointer">
                                                            <i className="fa-duotone fa-user fa-xl theme-color"></i>
                                                        </Link>
                                                    </div>
                                                </li>
                                            )}
                                            <li></li>
                                            <Cart define={define} addItem={addItem}/>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="mobile-menu d-md-none d-block mobile-cart">
                <ul>
                    <li className="active">
                        <a href="index-2.html">
                            <i className="iconly-Home icli"></i>
                            <span>Anasayfa</span>
                        </a>
                    </li>

                    <li className="mobile-category">
                        <a href="#">
                            <i className="iconly-Category icli js-link"></i>
                            <span>Kategoriler</span>
                        </a>
                    </li>

                    <li>
                        <a href="search.html" className="search-box">
                            <i className="iconly-Search icli"></i>
                            <span>Ara</span>
                        </a>
                    </li>

                </ul>
            </div>
        </div>
    );
};
export default Header;
