import React from 'react';
import {user} from "../helpers";
import Login from "../screens/auth/login";

const Middleware = ({addItem, define, Component}) => {
    if(user()){
        return (
            <Component addItem={addItem} define={define} />
        );
    } else {
        return (
            <Login addItem={addItem} define={define} reload={true} />
        );
    }

};
export default Middleware;
