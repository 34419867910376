import React, {useEffect, useState} from 'react';
import {Button, Card} from "react-bootstrap";
import InputMask from "react-input-mask";
import Loading from "../../components/other/loading";
import sentOTP from "../../redux/actions/sentOTP";
import OtpInput from 'react-otp-input';
import getLogin from "../../redux/actions/getLogin";
import {notify, user} from "../../helpers";
import {toast} from "react-toastify";

const Register = ({usr, addItem, loading, settings, setLoading}) => {
    const [loginText, setLoginText] = useState('Devam Et');
    const [phoneNumber, setPhoneNumber] = useState('05');
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userConfirmPassword, setUserConfirmPassword] = useState('');
    const [enterOTP, setEnterOTP] = useState(false);
    const [otp, setOtp] = useState(false);
    const [error, setError] = useState(false);
    useEffect(() => {
        if (user()) {
            location.href = '/';
        }
    }, []);
    useEffect(() => {
        if (otp.length === 6) {
            setLoginText('Doğrulanıyor...')
            getLogin(phoneNumber, otp, true).then(r => {
                if (!r.status) {
                    setLoginText('Doğrulama Bekleniyor...');
                    setOtp('')
                    setError('Doğrulama kodunu hatalı girdiniz. Lütfen kontrol edin ve yeniden deneyin.');
                } else {
                    setLoginText('Yönlendiriliyor...')
                    localStorage.setItem('access_token', r.data.access_token);
                    localStorage.setItem('user', JSON.stringify(r.data.user));
                    location.reload();
                }

            })
        }
    }, [otp]);
    const handleClose = () => {
        setLoginModal(false)
    }

    const handleSetNumber = (number) => {
        let num = number.target.value;
        num = num.replace(/\D|^0+/g, '')
        setPhoneNumber(num)
    }

    const handleSentOTP = () => {
        setError(false);
        if(!userName || !userEmail ||!userPassword){
            notify('Lütfen tüm alanları doğru şekilde doldurduğunuza emin olun ve yeniden deneyin.')
            return false;
        } else if(userPassword !== userConfirmPassword){
            setUserPassword('')
            setUserConfirmPassword('')
            notify('Şifreleriniz birbiriyle uyuşmuyor. Lütfen kontrol edin ve yeniden deneyin.')
            return false;
        }
        sentOTP(phoneNumber, {name: userName, email: userEmail, password: userPassword}).then(r => {
            if (r.message === 'otp_sent') {
                notify('Doğrulama kodu, telefonunuza gönderildi.', 'success');
                setLoginText('Doğrulama Bekleniyor...')
                setEnterOTP(true);
            } else if(!r.status) {
                setError(r.message)
                notify(error)
            }
        })
    }


    return (
        <section className="login-section">
            <div className="login-bg"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 offset-lg-3">
                        <Card>
                            <div className="card-header">
                                <h4 className="mb-3">Üyelik Oluşturun</h4>
                            </div>
                            <Card.Body>
                                <div className="p-4">
                                    {!enterOTP && (
                                        <>
                                            <div className="form-group mb-4">
                                                <label htmlFor="" className="mb-1">Adınız Soyadınız</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(val) => setUserName(val.target.value)}
                                                    value={userName}
                                                />
                                            </div>
                                            <div className="form-group mb-4">
                                                <label htmlFor="" className="mb-1">E-Posta Adresiniz</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(val) => setUserEmail(val.target.value)}
                                                    value={userEmail}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group mb-4">
                                                        <label htmlFor="" className="mb-1">Şifreniz</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            onChange={(val) => setUserPassword(val.target.value)}
                                                            value={userPassword}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group mb-4">
                                                        <label htmlFor="" className="mb-1">Şifrenizi Onaylayın</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            onChange={(val) => setUserConfirmPassword(val.target.value)}
                                                            value={userConfirmPassword}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="" className="mb-1">Telefon Numaranız</label>
                                                <InputMask mask="0\(599) 999 99 99" className="form-control"
                                                           placeholder="0(5__) ___ __ __"
                                                           onChange={(number) => handleSetNumber(number)}/>
                                                <small className="text-muted">Doğrulama kodu alabileceğiniz aktif bir
                                                    telefon numarası yazın.</small>
                                            </div>
                                        </>
                                    )}
                                    {enterOTP && (
                                        <div className="otp-inputs">
                                            <h6 className="mb-4">Lütfen telefonunuza gelen onay kodunu girin.</h6>
                                            <OtpInput
                                                value={otp}
                                                onChange={setOtp}
                                                numInputs={6}
                                                renderSeparator={<span>-</span>}
                                                renderInput={(props) => <input {...props} />}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Card.Body>
                            <div className="card-footer">
                                <a href="" className="me-auto text-muted d-inline-block mt-2"><small><i
                                    className="fa-regular fa-life-ring"></i> Giriş
                                    yapamıyorum!</small></a>
                                <button className="btn float-end bg-theme-color hover-bg-light btn-lg"
                                        disabled={phoneNumber.length === 10 && !enterOTP ? false : (enterOTP)}
                                        onClick={() => handleSentOTP()}>
                                    <i className={!enterOTP ? 'fa-light fa-arrow-right me-1' : 'fa-light fa-loader fa-spin me-1'}></i>
                                    <b>{loginText}</b>
                                </button>
                            </div>
                            <div className="card-footer bg-theme-light text-center">
                                <div className="ms-auto me-auto">
                                    <p className="m-0">Henüz hesabınız yok mu? <a href=""
                                                                                  className="theme-color text-decoration-underline"><b>Üye
                                        Ol!</b></a></p>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Register;
