import React, {useEffect, useState} from 'react';
import getCategories from "../../../redux/actions/getCategories";
import {Link} from "react-router-dom";
import {slugify} from "../../../helpers";

const Categories = () => {
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        getCategories(null).then(r => {
            setCategories(r.data);
        });
    }, []);
    return (
        <ul className="category-list">
            {!categories || !categories.length && (
                <li className="onhover-category-list not-found-category">Yükleniyor...</li>
            )}
            {categories && categories.length > 0 && categories.map((item, index) => (
                <li className="onhover-category-list">
                    <Link to={`/kategori/${slugify(item.name)}/${item.id}`} className="category-name">
                        <img src={item.image_url} alt="{ item.name }"/>
                        <h6>{ item.name }</h6>
                        <i className="fa-solid fa-angle-right"></i>
                    </Link>
                </li>
            ))}
        </ul>
    );
};

export default Categories;
