import React, {useEffect, useState} from 'react';
import Slider from "./blocks/slider";
import Categories from "./blocks/categories";
import getShopInformations from "../../redux/actions/getShopInformations";
import Section from "./blocks/section";

const Index = ({define, addItem}) => {
    const [shop, setShop] = useState([]);
    useEffect(() => {
        addItem('temp.loading', true)
        if (define.perm.latitude && define.perm.longitude) {
            getShopInformations(define.perm.latitude, define.perm.longitude).then(r => {
                addItem('temp.loading', false)
                setShop(r.data);
            });
        }
    }, [define.perm.latitude, define.perm.longitude]);
    return (
        <div>
            <section className="home-section-2 overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-3">
                            <Categories/>
                        </div>
                        <div className="col-lg-9 col-md-9">
                            <img onClick={() => handleLogin()} src="../assets/images/banner-reklam.jpeg" className="mb-3" width="100%" alt="" />
                            <Slider items={shop?.sliders}/>
                        </div>
                    </div>
                </div>
            </section>
            <Section items={shop?.sections} addItem={addItem} />
        </div>
    );
};

export default Index;
