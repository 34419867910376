import React, {useEffect} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';

const Slider = ({items}) => {

    return (
        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
        >
            {!items || !items.length && (
                <SwiperSlide>
                    <div className="home-contain rounded-0 p-0 placeholder-bg">
                        <img src="assets/images/spacers/banner-spacer.png"
                             className="img-fluid lazyload br-4" alt=""/>
                    </div>
                </SwiperSlide>
            )}
            {items && items.length > 0 && items.map((item, index) => (
                <SwiperSlide>
                    <div className="home-contain rounded-0 p-0">
                        <img src={item.image_url}
                             className="img-fluid lazyload br-4" alt=""/>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default Slider;
