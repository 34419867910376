import axiosInstance from "../helpers/axios";

export const getProducts = async (options) => {
    try {
        let url = '/customer/products';
        const response = await axiosInstance.post(url, options); // API'den konumları almak için GET isteği
        return response.data; // İstenen veri buradan dönecektir
    } catch (error) {
        // Hata durumunda burada hata işleme veya yönetimi yapılabilir
        console.error('Error fetching locations:', error);
        throw error; // Hata durumunda çağrıyı yapan yere hatayı iletmek için throw kullanılabilir
    }
};

export default getProducts;
